var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('sequential-entrance',{attrs:{"fromTop":""}},[_c('SellerHeader',{attrs:{"to":"/seller/menu","headerText":"Mis productos"}})],1),_c('v-main',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-0 pb-0 mt-3",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"app-input pt-0",attrs:{"label":"Buscar producto por nombre","color":"#00B8D9","flat":"","background-color":"#F4F5F7"},on:{"keyup":function($event){return _vm.searchProduct()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{staticClass:"pt-0 pb-0 mt-3",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.filtersSelects,"label":"Estado","dense":"","value":_vm.filtersSelected},on:{"change":_vm.filterByStatus}})],1),_c('v-col',{staticClass:"pt-0 pb-0 mt-3",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.categories,"label":"Categoría","item-value":'id',"item-text":"name","dense":""},on:{"change":_vm.getProducts},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('v-container',{staticClass:"app-list"},[_vm._l((_vm.productsFiltered),function(product,index){return (product.show_product)?_c('v-row',{key:index,staticClass:"align-center mb-5 d-flex justify-content-between rounded-lg",staticStyle:{"background-color":"#FAFAFA!important"},attrs:{"justify":"between"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{on:{"click":function($event){return _vm.goToProductDetail(product)}}},[_c('p',{staticClass:"mb-0 font-weight-bold text-color-soft-black",class:[
                        product.total_tickets_active >= product.max_quote ||
                        product.sell_simple > 0
                          ? 'text-color-gray'
                          : 'text-color-blue',
                      ],staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(product.id)+" - "+_vm._s(product.name)+" ")]),_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('strong',[_vm._v("Categoría: ")]),_vm._v(_vm._s(product.category.name)+" ")]),_c('p',{staticClass:"mb-0 text-color-soft-black",class:[
                        product.total_tickets_active >= product.max_quote ||
                        product.sell_simple > 0
                          ? 'text-color-gray'
                          : 'text-color-blue',
                      ],staticStyle:{"font-size":"11px !important","color":"#1e87fd"}},[_c('strong',[_vm._v("$ "+_vm._s(product.price))])]),_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('span',{staticClass:"dot",staticStyle:{"background-color":"green"}}),_vm._v("Válido desde "+_vm._s(_vm.dateToHours(product.initial_date))+" hasta "+_vm._s(_vm.dateToHours(product.finish_date))+" ")]),(product.tolerance == null)?_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('span',{staticClass:"dot",staticStyle:{"background-color":"yellow"}}),_vm._v("Sin tolerancia ")]):_vm._e(),(product.tolerance != null)?_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('span',{staticClass:"dot",staticStyle:{"background-color":"yellow"}}),_vm._v("Tolerancia: "+_vm._s(product.tolerance)+" minutos ")]):_vm._e(),(
                        product.comision > 0 &&
                          product.comision_type == 'percentage'
                      )?_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"font-size":"16px!important"}},[_vm._v(" mdi-currency-usd ")]),_vm._v(" Comisión: "+_vm._s(product.comision)+"% ")],1):_vm._e(),(
                        product.comision > 0 &&
                          product.comision_type == 'fixed'
                      )?_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"font-size":"16px!important"}},[_vm._v(" mdi-currency-usd ")]),_vm._v(" Comisión: $ "+_vm._s(product.comision)+" ")],1):_vm._e(),(product.comision == 0 || !product.comision)?_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"font-size":"16px!important"}},[_vm._v(" mdi-currency-usd ")]),_vm._v(" Sin comisión ")],1):_vm._e(),_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"font-size":"16px!important"}},[_vm._v(" mdi-ticket-confirmation-outline ")]),_vm._v(" Evento: "+_vm._s(product.event.name)+" ")],1),(
                        product.sale_date_message &&
                          product.sale_date_message !== ''
                      )?_c('p',{staticClass:"mb-0 text-color-soft-black",staticStyle:{"font-size":"11px !important"}},[_c('v-icon',{staticStyle:{"font-size":"16px!important"}},[_vm._v(" mdi-clock-outline ")]),_vm._v(" "+_vm._s(product.sale_date_message)+" ")],1):_vm._e()])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[(_vm.viewSellerInProducts == 1)?_c('div',[_c('v-icon',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(`modal-${index}`),expression:"`modal-${index}`"}],on:{"click":function($event){return _vm.getSellersList(product.id)}}},[_vm._v(" mdi-account-multiple ")]),_c('b-modal',{staticClass:"px-0",attrs:{"id":'modal-' + index,"title":'Vendedores del producto (' +
                          _vm.productSellers.length +
                          ')',"ok-only":"","ok-variant":"info","ok-title":"Cerrar"}},[_c('h4',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(product.name)+" - $ "+_vm._s(product.price)+" ")]),_c('div',{staticClass:"container my-1"},[_c('ul',{staticClass:"list-group"},_vm._l((_vm.productSellers),function(seller,indexSeller){return _c('li',{key:indexSeller,staticClass:"list-group-item"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(indexSeller + 1)+" - ")]),_vm._v(_vm._s(seller.fullname)+" ")])}),0)])])],1):_vm._e(),(
                      _vm.total_products_sold_type &&
                        _vm.total_products_sold_type != 'hidden'
                    )?_c('div',[_c('v-chip',{staticStyle:{"background-color":"#FAFAFA!important"},attrs:{"color":"font-weight-bold","x-small":""}},[(_vm.total_products_sold_type == 'nominal')?_c('span',{class:[
                          product.total_tickets_active >= product.max_quote ||
                          product.sell_simple > 0
                            ? 'text-color-gray'
                            : 'text-color-blue',
                        ]},[_vm._v(" "+_vm._s(_vm.ocupate(product.total_tickets_active))+"/"+_vm._s(product.max_quote)+" ")]):_vm._e(),(_vm.total_products_sold_type == 'percentage')?_c('span',{class:[
                          product.total_tickets_active >= product.max_quote ||
                          product.sell_simple > 0
                            ? 'text-color-gray'
                            : 'text-color-blue',
                        ]},[_vm._v(" "+_vm._s(( (_vm.ocupate(product.total_tickets_active) / product.max_quote) * 100 ).toFixed())+"% ")]):_vm._e()]),_c('b-progress',{attrs:{"max":product.max_quote}},[(
                          product.max_quote == product.total_tickets_active
                        )?_c('b-progress-bar',{attrs:{"variant":"danger","value":_vm.ocupate(product.total_tickets_active),"label":`
                        ${(
                          (_vm.ocupate(product.total_tickets_active) /
                            product.max_quote) *
                          100
                        ).toFixed()}%`}}):_c('b-progress-bar',{attrs:{"value":_vm.ocupate(product.total_tickets_active),"label":`${(
                            (_vm.ocupate(product.total_tickets_active) /
                              product.max_quote) *
                            100
                          ).toFixed()}%`}})],1)],1):_vm._e()])],1):_vm._e()}),(_vm.loading)?_c('v-row',_vm._l((_vm.skeletonCount),function(skeleton,index){return _c('v-container',{key:index,staticClass:"align-center d-flex justify-content-between rounded-lg mb-5",staticStyle:{"background-color":"#FAFAFA!important"},attrs:{"justify":"between"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('b-skeleton',{attrs:{"width":"60%"}}),_c('b-skeleton',{attrs:{"width":"90%"}}),_c('b-skeleton',{attrs:{"width":"50%"}}),_c('b-skeleton',{attrs:{"width":"30%"}})],1),_c('v-col',{staticClass:"pl-0 text-center",attrs:{"cols":"2"}},[_c('b-skeleton',{attrs:{"type":"avatar"}})],1)],1)}),1):_vm._e()],2)],1)],1)],1),_c('v-row',[_c('v-pagination',{staticStyle:{"margin":"auto"},attrs:{"length":_vm.totalPages,"total-visible":"6"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }