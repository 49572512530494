
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import {
  getEventDetails,
} from '@/services/seller.service'
import { Total } from '@/interfaces/seller/sales.interface'
import { getMyData } from '@/services/user.service'
import {
  getSeparatedTotals,
  getLocationsWhereUserIsBoss,
} from '@/services/seller.service'
import { Event } from '@/interfaces/product.interface'
import { ErrorMessages } from '@/helpers/constants'
import { eventDate, eventDateTime } from '@/helpers/date'
import SalesComponent from '@/components/Seller/components/SalesComponent.vue'

@Component({
  components: {
    'sales-component': SalesComponent,
  },
})
export default class SellerMenuButtons extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent')

  public viewGlobalReport = false
  public viewMarketingReport = false
  public business_admin = false
  public editProducts = false
  public viewAssistants = false
  public viewSalesForLocationBoss = false
  public eventData: Event[] = []
  public recepcionists = []
  public skeletonCount = [0, 1, 2, 3, 4]
  public loading = false
  public salesLoading = true
  public page = 1
  public totals: Total[] = []

  public vip_exist = []
  hasProductsTypeQR = false

  @Action setTab!: Function
  @Action setIsEditCard!: Function
  @Action setUserLinks!: Function

  get eventDateInit(): string {
    return eventDate(this.eventData.initial_date)
  }

  get dateInitEvent(): string {
    return eventDateTime(this.eventData.initial_date)
  }

  protected v2(route: string, path: string = ''): void {
    const eventId = localStorage.getItem('selectedEvent')
    const jwt = localStorage.getItem('access_token')
    window.location.href =
      process.env.VUE_APP_REPORTS_APP_URL +
      route +
      eventId +
      path +
      '?jwt=' +
      jwt
  }

  async getEventDetails() {
    try {
      const { data } = await getEventDetails(this.eventId, true)
      this.eventData = data

      // Guardar custom_fields en local storage para utilizarlos mas adelante
      if (data.custom_fields.length) {
        if (data.custom_fields[0])
          localStorage.setItem('custom_field_1', data.custom_fields[0].label)
        if (data.custom_fields[1])
          localStorage.setItem('custom_field_2', data.custom_fields[1].label)
        if (data.custom_fields[2])
          localStorage.setItem('custom_field_3', data.custom_fields[2].label)
      }

      // Permisos relacionados al Evento
      this.viewGlobalReport = data.global_report
      this.viewMarketingReport = data.report_marketing

      this.viewAssistants = data.view_assistants
      this.editProducts = data.edit_products
      this.hasProductsTypeQR = data.hasProductsTypeQR
    } catch (e) {
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }


  async mounted() {
    this.loading = true
    this.salesLoading = true
    await this.getEventDetails()
    await this.getLocationsWhereBoss()
    await this.getMySales()
    this.loading = false

    const user = (await getMyData()).data
    this.business_admin = user.business_admin
  }

  async getMySales() {
    this.salesLoading = true
    const response = await getSeparatedTotals(this.eventId)
    this.totals = response
    this.salesLoading = false
  }

  async getLocationsWhereBoss() {
    const response = await getLocationsWhereUserIsBoss()
    this.viewSalesForLocationBoss =
      response.data.data.length >= 1 ? true : false
  }

  v2Birthday(route: string): void {
    const jwt = localStorage.getItem('access_token')
    window.location.href =
      process.env.VUE_APP_REPORTS_APP_URL + route + '?jwt=' + jwt
  }

  v2Ages(route: string): void {
    const eventId = localStorage.getItem('selectedEvent')
    const jwt = localStorage.getItem('access_token')

    window.location.href =
      process.env.VUE_APP_ADMIN_DOMAIN +
      '/reports/global/' +
      eventId +
      route +
      '?jwt=' +
      jwt
  }

  goToAllLinks() {
    this.$router.push({
      name: 'all-links',
    })
  }
}
